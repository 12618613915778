<template>
  <div id="login_box">
    <div class="box_left">
      <el-carousel :interval="5000">
        <el-carousel-item class="slide1">
          <div class="bg_box">
            <img src="../../assets/login_banner/home_mask.png" alt="" />
          </div>
          <div class="content_box" style="--h3-color: #2574eb; --ul-color: #576077">
            <h3>
              {{ $t('login.loginBannerHomeTitle1') }}<br />
              {{ $t('login.loginBannerHomeTitle2') }}
            </h3>
            <div>
              <ul>
                <li v-for="num in 3" :key="num">
                  {{ $t('login.loginBannerHomeLi' + num) }}
                </li>
              </ul>
              <div class="img_box">
                <img :src="getBannerImg('home', num)" alt="" v-for="num in 3" :key="num" />
                <p>{{ $t('login.loginBannerHomeLabel') }}</p>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="slide2">
          <div class="bg_box">
            <img src="../../assets/login_banner/inventory_mask.png" alt="" />
          </div>
          <div class="content_box" style="--h3-color: #ff8a00; --ul-color: #776557">
            <h3>
              {{ $t('login.loginBannerInventoryTitle1') }}<br />
              {{ $t('login.loginBannerInventoryTitle2') }}
            </h3>
            <div>
              <ul>
                <li v-for="num in 3" :key="num">
                  {{ $t('login.loginBannerInventoryLi' + num) }}
                </li>
              </ul>
              <div class="img_box">
                <img :src="getBannerImg('inventory', num)" alt="" v-for="num in 3" :key="num" />
                <p>{{ $t('login.loginBannerInventoryLabel') }}</p>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="slide3">
          <div class="bg_box">
            <img src="../../assets/login_banner/visitor_mask.png" alt="" />
          </div>
          <div class="content_box" style="--h3-color: #2fb480; --ul-color: #577766">
            <h3>
              {{ $t('login.loginBannerVisitorTitle1') }}<br />
              {{ $t('login.loginBannerVisitorTitle2') }}
            </h3>
            <div>
              <ul>
                <li v-for="num in 3" :key="num">
                  {{ $t('login.loginBannerVisitorLi' + num) }}
                </li>
              </ul>
              <div class="img_box">
                <img :src="getBannerImg('visitor', num)" alt="" v-for="num in 4" :key="num" />
                <p>{{ $t('login.loginBannerVisitorLabel') }}</p>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="box_right">
      <div class="logo_box">
        <img src="../../assets/logo_mini.png" width="58" />
        <p>{{ $t('login.loginTitle') }}</p>
      </div>
      <!-- 联系方式和语言 -->
      <div class="contact_n_language_box">
        <!-- 联系方式 -->
        <el-dropdown class="contact_dropdown" trigger="click" placement="bottom-start" @visible-change="contactVisibleChange" :class="{ is_active: isContactPopperActive }">
          <span class="el-dropdown-link">
            <i style="font-size: 18px" class="el-icon-baseline-phone"></i>
            <span>{{ $t('login.contactUs') }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item class="contact_n_language_item" v-for="cItem in contactList" :key="cItem.id">
              <template v-if="cItem.id === 'email'">
                <i class="el-icon-email"></i>
                <span
                  ><a :href="'mailto:' + cItem.value">{{ cItem.label }}</a></span
                >
              </template>
              <template v-else>
                <i class="el-icon-baseline-phone"></i>
                <span
                  ><a :href="'tel:' + cItem.value">{{ cItem.label }}</a></span
                >
              </template>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 语言 -->
        <el-dropdown class="language_dropdown" trigger="click" placement="bottom-start" @command="switchLanguage">
          <span class="el-dropdown-link">
            <i style="font-size: 18px" class="el-icon-language"></i>
            <span>{{ currentLang.label }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item class="contact_n_language_item" v-for="lItem in languageList" :key="lItem.value" :command="lItem.value" :class="{ is_active: currentLang.value === lItem.value }">
              <span>{{ lItem.label }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <!-- 登录表单 -->
      <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef" :validate-on-rule-change="false" @keyup.enter.native="login">
        <!-- 手机或账号登录tab -->
        <template v-if="!isshowmicrosoft">
          <el-tabs v-model="loginMethod" @tab-click="handleLoginMethodTabClick">
            <el-tab-pane :label="$t('login.phoneLogin')" name="phone">
              <el-form-item prop="phone">
                <el-input type="tel" :placeholder="$t('login.configPhone')" v-model="loginForm.phone" class="input-with-select">
                  <el-select v-model="loginForm.areacode" slot="prepend">
                    <el-option :key="countryKey" :label="country.name + (country.name ? ' +' : '') + country.id" :value="country.id" v-for="(country, countryKey) in countryList"> </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item prop="password" class="password_box">
                <el-input show-password :placeholder="$t('login.accountPass')" v-model="loginForm.password">
                  <!-- <span slot="suffix" v-show="loginForm.password.length > 0"
                                    class="password-operation" @click="togglePassword">
                                    <img
                                        :src="!togglePasswordStatus ? '../../images/showPassword.png' : '../../images/hidePassword.png' " />
                                </span> -->
                </el-input>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane :label="$t('login.accLogin')" name="account">
              <el-form-item prop="peabbr">
                <el-input type="text" v-model="loginForm.peabbr" :placeholder="$t('login.peshortName')"> </el-input>
              </el-form-item>
              <el-form-item prop="username">
                <el-input type="text" v-model="loginForm.username" :placeholder="$t('login.accountId')"> </el-input>
              </el-form-item>
              <el-form-item prop="accountPassword" class="password_box">
                <el-input show-password :placeholder="$t('login.accountPass')" v-model="loginForm.accountPassword">
                  <!-- <span slot="suffix" v-show="loginForm.accountPassword.length > 0"
                                    class="password-operation" @click="togglePassword">
                                    <img
                                        :src="!togglePasswordStatus ? '../../images/showPassword.png' : '../../images/hidePassword.png' " />
                                </span> -->
                </el-input>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
        </template>
        <!-- 第三方登录tab -->
        <template v-else>
          <el-tabs v-model="loginMethod" @tab-click="handleLoginMethodTabClick">
            <el-tab-pane :label="$t('login.hybrid')" name="hybrid">
              <el-form-item prop="hybrid_user">
                <el-input type="text" v-model="loginForm.hybrid_user" :placeholder="$t('login.email')"> </el-input>
              </el-form-item>
              <el-form-item prop="img_code">
                <el-col :span="16">
                  <el-input type="text" v-model="loginForm.img_code" :placeholder="$t('login.vercodeTips')" maxlength="4"> </el-input>
                </el-col>
                <el-col :span="7" :offset="1">
                  <img :src="imgsrc" @click="getImageCode" style="cursor: pointer; border: 1px solid #f3f3f7; border-radius: 6px; vertical-align: top" />
                </el-col>
                <!-- <div class="user-code-box">
                                <el-input type="text" v-model="loginForm.img_code"
                                    :placeholder="$t('login.vercodeTips')" maxlength="4">
                                </el-input>
                                <div class="imgcode_box">
                                    <img :src="imgsrc" @click="getImageCode" style="cursor: pointer;">
                                </div>
                            </div> -->
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
        </template>
      </el-form>
      <div class="form_error_tips_box">
        <span v-show="errmsg">{{ errmsg }}</span>
      </div>
      <!-- 免费注册和忘记密码 -->
      <div class="register_forgot_box" v-if="!isshowmicrosoft">
        <!-- 免费注册 -->
        <a v-if="!isCNSite" class="register-now" @click="register"
          ><span>{{ $t('login.freeregnow') }}</span></a
        >
        <!-- 忘记密码 -->
        <div class="forgot" v-if="loginMethod === 'phone'">
          <a @click="forgot">{{ $t('login.forgotPass') }}</a>
        </div>
        <div class="forgot" v-if="loginMethod === 'account'">
          <a @click="forgotAccount">{{ $t('login.forgotPass') }}</a>
        </div>
      </div>
      <!-- 第三方 -->
      <div v-if="!isshowmicrosoft">
        <div class="loginmethodscontain">
          <span class="loginmethods">———— {{ $t('login.otherLoginMethods') }} ————</span>
        </div>
        <div class="microsofticon">
          <img src="../../assets/microsoft-icon.svg" alt="微软标题" style="cursor: pointer" @click="showmicrosoft" />
        </div>
      </div>
      <!-- 返回和登录提交按钮 -->
      <div class="btn_box">
        <el-button v-if="isshowmicrosoft" class="back_btn" type="primary" @click="backForm">
          {{ $t('common.back') }}
        </el-button>
        <el-button class="login_btn" type="primary" @click="login">{{ $t('login.logIn') }} </el-button>
      </div>
      <div class="copyright" v-if="langcode !== 'tw'">
        <span>
          {{ $t('common.copyrightDesc') }}
          <a v-if="langcode === 'zh'" style="text-decoration: none" href="https://beian.miit.gov.cn/">粤ICP备15010411号</a>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
