// import axios from "axios";
export default {
  name: 'LoginHybridPage',
  data() {
    return {
      hybridObj: {}
    }
  },
  created() {
    sessionStorage.clear();
    let hybridUrl = window.location.href.split('?')[1];
    let hybridArr = hybridUrl.split('&');
    hybridArr.forEach((hybriditem) => {
      let hybriditemArr = hybriditem.split('=');
      this.hybridObj[hybriditemArr[0]] = hybriditemArr[1]
    })
    console.log(this.hybridObj);
    this.loginSecondHybrid()
  },
  methods: {
    loginSecondHybrid() {
      this.$http.post('/common/login', {
        "hrid": this.hybridObj.hrid,
        "authkey": this.hybridObj.authKey,
      }, { disabledAuth: true }).then( (response) => {
        let datas = response.data;
        if (datas.success) {
          let isOperate = datas.content.isdataadmin == 'Y' ? 'true' : 'false';
          let ispemgr = datas.content.ispemgr == 'Y' ? 'true' : 'false';
          // sessionStorage.setItem("loginPhone", (self.phone));
          // sessionStorage.setItem("isOperate", ('true'));

          this.$store.commit('SET_SELFREGISTER', datas.content.selfregister)
          this.$store.commit('SET_ISPEMGR', ispemgr)
          this.$store.commit('SET_ISOPERATE', isOperate)

          this.$store.commit('SET_LOGINID', datas.content.hrid)
          this.$store.commit('SET_PENUM', datas.content.penum)

          // sessionStorage.setItem("isOperate", (isOperate));
          // sessionStorage.setItem("loginid", (datas.content.hrid));
          // sessionStorage.setItem("penum", (datas.content.penum));
          // sessionStorage.setItem("displayname", (datas.content.displayname));
          // sessionStorage.setItem("language", (datas.content.language));
          // 切换语言
          this.$store.commit('SET_LANG', datas.content.language)
          this.$i18n.locale = this.$store.getters.lang

          // sessionStorage.setItem("selfregister", (datas.content.selfregister));
          // sessionStorage.setItem("ispemgr", (ispemgr));
          sessionStorage.setItem("authKey", (datas.content.authKey || "")); // toekn authkey
          sessionStorage.setItem("publicKey", datas.content.publicKey);

          if (datas.content.expire) {
            sessionStorage.setItem("expire", (datas.content.expire));
          }
          this.$router.replace("/home")
        } else if (datas.isAcctInAnotherHost) {
          this.$alert(this.$t('login.webSiteVersion', { anotherSite: this.$utils.getAnotherSite() }), this.$t('common.alertPrompt'), {
            type: 'error',
            center: true,
            callback: () => {
              window.location.href = this.$utils.getAnotherSite();
            }
          })
        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true,
            callback: () => {
              this.$router.replace("/login")
            }
          })
          // self.showerr = true
          // self.errmsg = datas.message
          // self.logining = false
        }
      })
    }

  }
}